import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import '../styles/global.css';

import Header, { HeaderProps } from '../components/Common/Header';
import LayoutRoot from '../components/Common/LayoutRoot';
import LayoutMain from '../components/Common/LayoutMain';
import Footer from '../components/Common/Footer';
interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
      siteUrl: string;
    };
  };
}

const IndexLayout: React.FC<{
  transparent?: HeaderProps['transparent'];
  mode?: HeaderProps['mode'];
  meta?: React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>[];
}> = ({ children, transparent, mode, meta }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
              keywords
              siteUrl
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <LayoutRoot>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              ...(meta ?? []),
              { name: 'description', content: data.site.siteMetadata.description },
              { name: 'keywords', content: data.site.siteMetadata.keywords },
              { property: 'og:title', content: data.site.siteMetadata.title },
              { property: 'og:image', content: `${data.site.siteMetadata.siteUrl}/assets/common/thumbnail.png` },
            ]}
          />
          <Header title="Orange Cap Innovative" transparent={transparent} mode={mode} />
          <LayoutMain>
            {children}
          </LayoutMain>
          <Footer />
        </LayoutRoot>
      )}
    />
  );
};

export default IndexLayout;
