import React from 'react';
import { Global, css } from '@emotion/core';
import { styled } from 'twin.macro';
import normalize from '../../styles/normalize';

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => {
  return (
    <>
      <Global styles={() => css(normalize)} />
      <StyledLayoutRoot className={className}>
        {children}
      </StyledLayoutRoot>
    </>
  );
};

export default LayoutRoot;
