import React from 'react';
// import { useLocation } from '@reach/router';
// import { Link } from 'gatsby';
import { styled } from 'twin.macro';
// import { PageRouteContext, TPreviousRoute, TPreviousRouteContext } from '../../utils/contexts/PageRouteContext';

const StyledLayoutMain = styled.main`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
`;

// const BackLinkContainer = styled.section(() => [
//   tw`hidden relative md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto lg:flex items-center gap-x-2 mb-4`
// ]);

interface LayoutMainProps {
  className?: string;
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => {
  // REMOVED BACK TO PREVIOUS PAGE BECUASE IT'S OUTDATED

  // const { pathname } = useLocation();
  // const { routeData, handleSetRouteData } = useContext(PageRouteContext) as TPreviousRouteContext;

  // const handleSetRoute = (currentRoute: TPreviousRoute) => {
  //   handleSetRouteData({ routeName: currentRoute.routeName, displayRouteName: currentRoute.displayRouteName });
  // };

  return (
    <StyledLayoutMain className={className}>
      {/* {pathname !== '/' && routeData.length > 0 && pathname !== routeData[0].routeName.toString() && (
        <BackLinkContainer>
          <Link
            className="text-spaceGray hover:no-underline transition transform hover:opacity-80"
            to={routeData[0].routeName}
            onClick={() => handleSetRoute(routeData[0])}
          >{'< '} Back to {routeData[0].displayRouteName}
          </Link>
        </BackLinkContainer>
      )} */}
      {children}
    </StyledLayoutMain>
  );
};

export default LayoutMain;
