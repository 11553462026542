import React, { useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { Fade as Hamburger } from 'hamburger-react';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import Logo from './Logo';
// import Container from './Container'
// import OCILogo from '../../assets/logo/OCI_with_text.svg';
// import MobileLogo from './MobileLogo';
import useOnScreen from '../../hooks/use-on-screen';
import { NavbarRoutes, TNavbarRoute } from '../../utils/NavbarRoutes';
import { DisplayRouteName, RouteName } from '../../utils/enums';
import { PageRouteContext, TPreviousRouteContext } from '../../utils/contexts/PageRouteContext';

export type HeaderProps = {
  title: string;
  transparent?: boolean;
  mode?: 'sticky' | 'fixed-faded';
};

interface LinkProps {
  transparent?: string;
  isHilighted: boolean;
}

interface StyledContainerProps {
  bgColor?: string;
  mode: Required<HeaderProps>['mode'];
}

const StyledHeader = styled.header<StyledContainerProps>(props => [
  tw`top-0 h-headerMobile lg:h-[70px] w-full z-50 flex items-center`,
  ...(props.bgColor ? [`background-color: ${props.bgColor};`] : []),
  ...(props.mode === 'sticky' ? [tw`sticky`] : []),
  ...(props.mode === 'fixed-faded' ? [tw`fixed transition-[background-color] duration-500`] : []),]);

const HeaderInner = styled.div(() => [
  tw`mx-auto py-0 px-pageContainerSpace xl:px-0 xl:px-0 items-center justify-between hidden lg:flex md:max-w-pageContainerSize w-full`
]);

const DropdownMobile = styled<any>(animated.div)(() => [
  tw`fixed flex flex-col top-0 h-screen w-screen bg-orange pt-20 pb-10 px-12 items-start justify-between z-50 sm:flex md:flex md:px-24 lg:hidden xl:hidden`
]);

const LinkContainer = styled.div(() => [
  tw`text-white grid gap-6 lg:auto-cols-max lg:grid-flow-col lg:flex-grow flex-shrink-0 justify-end lg:items-center`
]);

// const MobileHomepageLink = styled(Link) <LinkProps>`
//   ${tw`text-xl m-3 mx-0 hover:text-decoration[underline] font-medium text-white xl:m-3 xl:font-semibold`};
// `;

const HeaderInenerMobile = styled.div(() => [
  tw`flex justify-between items-center right-1.5 px-pageContainerSpace xl:px-0 pr-[10px] w-screen h-12 z-50 lg:top-4 lg:right-4 lg:hidden `
]);

const LogoImageBottom = styled.img(() => [tw`absolute bottom-10 h-[30px] left[calc(50vw - 51px - 48px)] xl:hidden`]);

const Header: React.FC<HeaderProps> = ({ title, transparent, mode = 'sticky' }) => {
  const { pathname } = useLocation();
  // const { routeData, handleSetRouteData } = useContext(PageRouteContext) as TPreviousRouteContext;

  let bgColor = '#E46B25';
  if (transparent) bgColor = 'white';

  const [isOpen, setIsOpen] = useState(false);
  const [isRendered, setisRendered] = useState(false);
  const springStyles = useSpring({
    transform: isOpen ? 'translateY(0px)' : 'translateY(-150vh)',
    config: { duration: 200 }
  });
  const triggerRef = useRef<HTMLElement>(null);
  const isTriggerSeen = useOnScreen(triggerRef, '50px');

  if (mode === 'fixed-faded') {
    bgColor = isTriggerSeen ? 'transparent' : bgColor;
    if (!isRendered) bgColor = 'transparent';
  }

  const handleSetNavbarTextColor = (navbarRoute: TNavbarRoute) => {
    if (pathname === RouteName.HOME) return 'text-white';
    if (pathname === navbarRoute.link) return 'text-spaceGray scale-110 font-medium';
    return 'text-orange';
  };

  // const handleSetRoute = (newRoute: TNavbarRoute) => {
  //   if (pathname !== newRoute.link) {
  //     if (routeData.length === 0) {
  //       const findNavbarDisplayRouteName = NavbarRoutes.find((item) => item.link === pathname);
  //       if (findNavbarDisplayRouteName) {
  //         handleSetRouteData(
  //           { routeName: newRoute.link, displayRouteName: newRoute.display_route_name },
  //           { routeName: findNavbarDisplayRouteName.link as RouteName, displayRouteName: findNavbarDisplayRouteName.display_route_name }
  //         );
  //       } else if (pathname === RouteName.HOME) {
  //         handleSetRouteData(
  //           { routeName: newRoute.link, displayRouteName: newRoute.display_route_name },
  //           { routeName: RouteName.HOME, displayRouteName: DisplayRouteName.HOME }
  //         );
  //       }
  //     } else {
  //       handleSetRouteData({ routeName: newRoute.link, displayRouteName: newRoute.display_route_name });
  //     }
  //   }
  // };

  useEffect(() => {
    setisRendered(true);
  }, []);

  return (
    <>
      <StyledHeader mode={mode} bgColor={bgColor}>
        <HeaderInner>
          <Logo title={title} transparent={transparent} />
          <LinkContainer>
            {NavbarRoutes.map((route) => (
              <Link
                key={route.label}
                to={route.link}
                className={`text-base transition transform hover:underline hover:scale-110 font-light ${handleSetNavbarTextColor(route)}`}
              // onClick={() => handleSetRoute(route)}
              >
                {route.label}
              </Link>
            ))}
            {/* <HomepageLink to="/work" transparent={transparent?.toString()}>
              WORK
            </HomepageLink>
            <HomepageLink to="/ourteam" transparent={transparent?.toString()}>
              OUR TEAM
            </HomepageLink>
            <HomepageLink to="/insights" transparent={transparent?.toString()}>
              INSIGHTS
            </HomepageLink>
            <HomepageLink to="/career" transparent={transparent?.toString()}>
              CAREER
            </HomepageLink>
            <HomepageLink to="/contact" transparent={transparent?.toString()}>
              CONTACT US
            </HomepageLink> */}
          </LinkContainer>
        </HeaderInner>
        <DropdownMobile style={springStyles}>
          <LinkContainer>
            {NavbarRoutes.map((route) => (
              <Link
                key={route.label}
                to={route.link}
                className="text-xl m-3 mx-0 hover:text-decoration[underline] font-normal text-white xl:m-3 xl:font-semibold"
                onClick={() => setIsOpen((prev) => !prev)}
              // onClick={() => {
              //   setIsOpen((prev) => !prev);
              //   handleSetRoute(route);
              // }}
              >
                {route.label}
              </Link>
            ))}
            {/* <MobileHomepageLink to="/work" onClick={() => setIsOpen(!isOpen)}>
              WORK
            </MobileHomepageLink>
            <MobileHomepageLink to="/ourteam" onClick={() => setIsOpen(!isOpen)}>
              OUR TEAM
            </MobileHomepageLink>
            <MobileHomepageLink to="/insights" onClick={() => setIsOpen(!isOpen)}>
              INSIGHTS
            </MobileHomepageLink>
            <MobileHomepageLink to="/career" onClick={() => setIsOpen(!isOpen)}>
              CAREER
            </MobileHomepageLink>
            <MobileHomepageLink to="/contact" onClick={() => setIsOpen(!isOpen)}>
              CONTACT US
            </MobileHomepageLink> */}
          </LinkContainer>
          {/* <LogoImageBottom src={OCILogo} /> */}
        </DropdownMobile>
        <HeaderInenerMobile>
          <Logo title={title} transparent={false} />
          <Hamburger
            // eslint-disable-next-line no-nested-ternary
            color={isOpen ? 'white' : transparent ? '#E46B25' : 'white'}
            size={20}
            toggled={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
            distance="lg"
            rounded
          />
        </HeaderInenerMobile>
      </StyledHeader>
      <span className="h-px w-full absolute" ref={triggerRef} />
    </>
  );
};

export default Header;
