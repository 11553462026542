import { DisplayRouteName, RouteName } from './enums';

export interface TNavbarRoute {
  label: string;
  link: RouteName;
  display_route_name: DisplayRouteName;
}

export const NavbarRoutes: TNavbarRoute[] = [
  {
    label: 'WORKS',
    link: RouteName.WORK,
    display_route_name: DisplayRouteName.WORKS,
  },
  {
    label: 'WHO WE ARE',
    link: RouteName.WHO_WE_ARE,
    display_route_name: DisplayRouteName.WORKS,
  },
  {
    label: 'OUR TEAM',
    link: RouteName.OUR_TEAM,
    display_route_name: DisplayRouteName.OUR_TEAM,
  },
  {
    label: 'INSIGHTS',
    link: RouteName.INSIGHTS,
    display_route_name: DisplayRouteName.INSIGHTS,
  },
  {
    label: 'CAREERS',
    link: RouteName.CAREER,
    display_route_name: DisplayRouteName.CAREER,
  },
  {
    label: 'CONTACT US',
    link: RouteName.CONTACT_US,
    display_route_name: DisplayRouteName.CONTACT_US,
  },
];