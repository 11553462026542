import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { Link, navigate } from 'gatsby';
import Container from './Container';
import FooterLogo from './FooterLogo';
import Logo from './Logo';
import { NavbarRoutes } from '../../utils/NavbarRoutes';
import OCILogo from '../../assets/logo/OCI_color.svg';
import FacebookLogo from '../../assets/common/logo-facebook.svg';

interface Section1Props {
  className?: string;
}

interface ColumnProps {
  bgColor?: string;
}

const FooterContainer = styled.footer(() => [tw`h-auto max-w-full bg-spaceGray`]);

const FooterInner = styled(Container)(() => [
  tw`flex flex-col py-6 px-pageContainerSpace xl:px-0 mx-auto xl:px-0 xl:py-10 items-center justify-between w-full md:max-w-pageContainerSize`
]);

const GridContainer = styled.div<ColumnProps>(() => [
  tw`grid grid-cols-12 gap-x-0 gap-y-10 md:gap-9 lg:gap-y-12 justify-center items-center w-full`
]);

const PARAGRAPH_STYLE = tw`text-sm lg:text-base leading-[24px] text-white mb-0`;
const Paragraph = styled.p(() => [PARAGRAPH_STYLE]);
const ParagraphDiv = styled.div`${PARAGRAPH_STYLE}`;

const AllRightsReserved = styled.p(() => [tw`text-xs lg:text-sm leading-[16px] text-white mb-0 font-light`]);

const Mission = styled.p(() => [tw`text-xs lg:text-sm text-white text-center mx-auto mt-1 font-light`]);

const Footer: React.FC<Section1Props> = ({ className }) => {
  return (
    <FooterContainer className={className}>
      <FooterInner>
        <div className="flex items-center w-max mr-auto">
          <img
            alt="Footer Logo"
            src={OCILogo}
            className="w-[40px] lg:w-[55px]"
          />
          <span className="ml-2 text-lg lg:text-xl text-white">Orange Cap Innovative</span>
        </div>
        {/* <Logo title="ORANGE CAP INNOVATIVE" className="flex w-full col-span-12 items-center mb-6 lg:mb-8 cursor-pointer" /> */}
        {/* <FooterLogo transparent={false} /> */}
        <div className="w-full flex flex-col lg:flex-row justify-between mt-6">
          <div className="w-full">
            <Paragraph className="self-start">
              Orange Cap Innovative Co. Ltd (Headquarter)
              <br /> Wannasorn Tower 9th Floor, Room No. 0904,
              <br className="hidden lg:block" /> Phayathai Rd, Thanon Phaya Thai,
              <br className="hidden bl:block" /> Ratchathewi, Bangkok 10400
            </Paragraph>
          </div>
          <div className="w-full flex justify-between mt-4 md:mt-0">
            <div className="flex flex-col gap-y-[2px]">
              <a
                target="_blank"
                href="mailto:contact@orangecapinnovative.com"
                rel="noopener noreferrer"
                className="text-white selection:text-white flex-grow-0 text-sm md:text-base"
              >
                contact@orangecapinnovative.com
              </a>
              <Paragraph>(+66) 065-554-7498</Paragraph>
              <a
                href="https://www.facebook.com/orangecapinnovative"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-fit mt-2 transition transform hover:scale-105"
              >
                <img
                  alt="Orange Cap Innovative Facebook Page"
                  src={FacebookLogo}
                  className="w-7 h-7"
                />
              </a>
            </div>
            <div className="hidden lg:flex flex-col gap-y-0 w-max items-end">
              {NavbarRoutes.map((route) => (
                <a
                  key={route.label}
                  href={route.link}
                  className="selection:text-white text-white"
                >
                  {route.label}
                </a>
              ))}
              {/* <Link to="/work" className="text-white hover:scale-105">
                WORK
              </Link>
              <Link to="/ourteam" className="text-white hover:scale-105">
                OUR TEAM
              </Link>
              <Link to="/insights" className="text-white hover:scale-105">
                INSIGHTS
              </Link>
              <Link to="/career" className="text-white hover:scale-105">
                CAREER
              </Link> */}
            </div>
          </div>
        </div>
        <div className="col-span-12 mx-auto mt-10">
          <AllRightsReserved className="mx-auto w-max">
            ©Orange Cap Innovative. All rights reserved.
          </AllRightsReserved>
          <Mission>
            Orange Cap Innovative is a software company that provides new business development services focusing on
            the use of technology.
          </Mission>
        </div>
      </FooterInner>
    </FooterContainer>
  );
};

export default Footer;
