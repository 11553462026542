import { useState, useLayoutEffect } from 'react';

const useOnScreen = (ref: React.RefObject<HTMLElement> | null = null, rootMargin = '0px', onlyOnce = false, ) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
        if (entry.isIntersecting && onlyOnce && ref?.current) {
          observer.unobserve(ref.current);
        }
      },
      {
        rootMargin
      }
    );
    if (ref?.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref?.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export default useOnScreen;
