import React from 'react';
import tw, { styled } from 'twin.macro';
import { navigate } from 'gatsby';
import OCILogo from '../../assets/logo/OCI.svg';
import OCILogoColor from '../../assets/logo/OCI_color.svg';

const LogoBlock = styled.div(() => [tw`flex items-center cursor-pointer`]);

const LogoTitle = styled.p<any>`
  ${tw`text-sm md:text-xl m-1.5 text-white font-semibold mt-0 mb-0`};
  ${props => (props.color ? `color: ${props.color}` : 'color: white')}
`;

const Logo = ({ title, transparent, className }: { title: string; transparent?: boolean; className?: string; }) => {
  return (
    <LogoBlock className={className} onClick={() => navigate('/')}>
      <img src={transparent ? OCILogoColor : OCILogo} alt="Logo" />
      <LogoTitle color={transparent ? '#E46B25' : 'white'}>{title}</LogoTitle>
    </LogoBlock>
  );
};

export default Logo;
