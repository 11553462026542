import * as React from 'react'
import tw, { styled } from 'twin.macro'

const StyledPage = styled.div(() => [tw`overflow-hidden`])

interface PageProps {
  className?: string
}

const Page: React.FC<PageProps> = ({ children, className }) => <StyledPage className={className}>{children}</StyledPage>

export default Page
