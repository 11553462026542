export enum TeamName {
  ENGINEERING = 'Engineering',
  MANAGEMENT = 'Management',
  DESIGN = 'Design',
  MARKETING = 'Marketing',
  PROJECT = 'Project',
  CUSTOMER_SUPPORT = 'Customer Support',
  PEOPLE_AND_OFFICE = 'People & Office',
  ALL = 'All',
}

export enum RouteName {
  HOME = '/',
  WORK = '/works',
  WHO_WE_ARE = '/who-we-are',
  OUR_TEAM = '/ourteam',
  INSIGHTS = '/insights',
  CAREER = '/career',
  CONTACT_US = '/contact',
}

export enum DisplayRouteName {
  HOME = 'Home',
  WORKS = 'Works',
  OUR_TEAM = 'Our Teams',
  INSIGHTS = 'Insights',
  CAREER = 'Career',
  CONTACT_US = 'Contact Us',
}